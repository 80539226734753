.q::before {
  border-radius: 1rem;
  content: '';
  background-image: linear-gradient(to bottom right, #11a2ff 0%, #93426F 100%);
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  position: absolute;
  z-index: -1;
}
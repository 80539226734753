.sun {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 9999px;
  box-shadow: 0 0 500px 13px #FC9601;
  left: 50%;
  transform: translate(-50%,-50%);
  top: 0%;
  background: #FC9601;
}
.connectBtn {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: initial !important;
    font-family: inherit !important;
    line-height: 0 !important;
    border-radius: 9999px !important;
    --tw-border-opacity: 1 !important;
    border: 1px solid rgba(255, 255, 255, var(--tw-border-opacity)) !important;
    padding: 1.4rem 1rem !important;
    min-width: 140px !important;
    justify-content: center !important;
}
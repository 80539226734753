@keyframes smoothscroll { 
  0% { 
    margin-left: 0px; 
  } 
  100% {
    margin-left: calc(-10/6 * 100%); 
  } 
}

.scoller { 
  -webkit-animation: smoothscroll 18s linear infinite; 
  -moz-animation: smoothscroll 18s linear infinite; 
  -ms-animation: smoothscroll 18s linear infinite; 
  -o-animation: smoothscroll 18s linear infinite; 
  animation: smoothscroll 18s linear infinite; 
}
